// $danger: #d9534f;
$google: #de5246;
// $blue: #007bff;
// $indigo: #6610f2;
// $purple: #6f42c1;
// $pink: #e83e8c;
// $red: #ff7851;
// $orange: #fd7e14;
// $yellow: #ffce67;
// $green: #56cc9d;
// $teal: #20c997;
// $cyan: #6cc3d5;
// $white: #fff;
// $gray: #888;
// $gray-dark: #343a40;
$primary: #78c2ad;
$secondary: #f3969a;
// $success: #56cc9d;
// $info: #6cc3d5;
// $warning: #ffce67;
// $danger: #ff7851;
$light: #f8f9fa;
// $dark: #343a40;

// :root {
//   --primary-color: #78c2ad;
// }

// .btn-primary  {
//   background-color: var(--primary-color);
// }

@import "~bootstrap/scss/bootstrap.scss";
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";
// @import "~bootstrap/scss/utilities";
// @import "~bootstrap/scss/grid";
// @import "./bootswatch";

a {
  color: $primary;
  text-decoration: none;
}

.btn-link:hover,
a:hover {
  opacity: 0.7;
}

.accordion {
  --bs-accordion-active-bg: transparent;
  --bs-accordion-bg: transparent;
  --bs-accordion-active-color: #78c2ad;
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2378c2ad'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2378c2ad'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0,0,0,.125);
}

.force-bold {
  font-weight: 800 !important;
}

.bg-transparent {
  background-color: transparent !important;
}
// buttons

// This is to prevent the underline persisting after click
.btn-primary,
.btn-secondary,
.btn-info {
  color: $light;
}

.btn-primary:disabled,
.btn-primary:hover,
.btn-secondary:disabled,
.btn-secondary:hover,
.btn-info:disabled,
.btn-info:hover {
  color: $light;
}

.btn-outline-primary:hover,
.btn-outline-secondary:hover,
.btn-outline-info:hover {
  color: $dark !important;
}

.btn-link {
  text-decoration: none;
}
.btn-link:focus {
  text-decoration: none !important;
}
.btn-link:hover:focus {
  text-decoration: underline !important;
}

.apple-btn {
  // background-color: black;
  height: 44px;
}

.google-btn {
  // background-color: $google;
  height: 44px;
}
.info_btn_custom {
  cursor: pointer;
}

.info_btn_custom:focus {
  outline: 0;
}

.rotate-clockwise {
  animation: rotationClockwise 2s infinite linear;
}
@keyframes rotationClockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate-counter-clockwise {
  animation: rotationCounterClockwise 2s infinite linear;
}

.rotate-counter-clockwise-slow {
  animation: rotationCounterClockwise 16s infinite linear;
}

@keyframes rotationCounterClockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}

.rotate-90 {
  animation: rotate90 20s linear infinite;
}

@keyframes rotate90 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-90deg);
  }
}
.slug-btn-outline-dark {
  @extend .btn-outline-dark;
}
.slug-btn-outline-dark:hover {
  background-color: black;
}
.slug-icon {
  font-size: 24px;
}

// colors
.bg-apple-music-grey {
  background-color: #f8f8fa;
}
.bg-danger {
  background-color: #ff7851;
}
.bg-pale-green {
  background-color: #effcdb;
}
.bg-pale-pink {
  background-color: #fdf0f2;
}
.bg-warning {
  background-color: #ffce67;
}

// Inputs
.fieldHelperText {
  padding-left: 0.5rem;
  font-size: 0.75rem;
}
.formikErrorMessage {
  color: red;
  font-size: 0.875rem;
  margin-top: 4px;
  padding-left: 0.5rem;
}

// Lists
.selectable-list-item {
  cursor: pointer;
}
.selected-list-item {
  background-color: #fdf0f2;
}
.song-list-item__title {
  font-size: 1rem;
}

// Misc
[id^="react-select-"],
.auto-slurp-playlist-select__single-value,
.auto-slurp-time-select__single-value,
.auto-slurp-timezone-select__single-value {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #888 !important;
}

.haiku {
  font-family: "Calligraffitti", cursive;
  font-size: 18px;
}

.a-popover,
a:focus {
  box-shadow: none;
  outline: none;
}

.a-popover,
a:hover {
  text-decoration: none;
}

// Modals
.modal {
  -webkit-overflow-scrolling: touch;
}

// Spacing
.sign-in-width {
  min-width: 300px;
  max-width: 576px;
}
.slug-width {
  min-width: 300px;
}

// Slugtube
.slug-tube__bg-placeholder {
  background-color: $light;
}

// Status Message
.status-emoji-xs {
  font-size: 48px;
}
.status-emoji-sm {
  font-size: 56px;
}
.status-emoji-md {
  font-size: 96px;
}
.status-emoji-lg {
  font-size: 96px;
}
.status-title-sm {
  font-size: 24px;
}
.status-title-md {
  font-size: 48px;
}
.status-title-lg {
  font-size: 56px;
}
.status-subtitle-sm {
  font-size: 16px;
}
.status-subtitle-md {
  font-size: 18px;
}
.status-subtitle-lg {
  font-size: 18px;
}

// Tutorial
.carousel-control-icon {
  font-size: 1.25rem;
}

// .carousel-indicators li {
//   border-top: $primary;
//   background-color: $primary;
// }

// .carousel-indicators .active {
//   border-top: darken($color: $primary, $amount: 5);
// }

.tutorial-emoji {
  font-size: 48px;
  margin-bottom: 16px;
}

.tutorial-lead {
  @extend .lead;
  font-size: 1rem;
}

.tutorial-page {
  margin-left: 32px;
  margin-right: 32px;
}

.tutorial-title {
  @extend .display-1;
  font-size: 36px;
  margin-bottom: 18px;
}

// Typography
.slug-title {
  @extend .display-1;
  font-size: 48px;
}
.slug-subtitle {
  @extend .display-1;
  font-size: 24px;
}
.slugTube-font {
  font-family: "Oswald", sans-serif;
}
.appleMusic-font {
  font-family: "Inter", sans-serif;
}

// User Settings
.border-user-settings {
  border-color: rgba(0, 0, 0, 0.125) !important;
}

@include media-breakpoint-up(sm) {
  // Typography
  .slug-title {
    font-size: 72px;
  }
  .slug-subtitle {
    font-size: 48px;
  }

  // Spacing
  .slug-width {
    min-width: 576px !important;
    max-width: 750px !important;
  }
  .sign-in-width {
    min-width: 400px !important;
    max-width: 400px !important;
  }

  // Status Message
  .status-emoji-xs {
    font-size: 72px;
  }
  .status-emoji-sm {
    font-size: 72px;
  }
  .status-emoji-md {
    font-size: 128px;
  }
  .status-emoji-lg {
    font-size: 88px;
  }
  .status-title-xs {
    font-size: 36px;
  }
  .status-title-sm {
    font-size: 36px;
  }
  .status-title-md {
    font-size: 64px;
  }
  .status-title-lg {
    font-size: 88px;
  }
  .status-subtitle-xs {
    font-size: 18px;
  }
  .status-subtitle-sm {
    font-size: 24px;
  }
  .status-subtitle-md {
    font-size: 24px;
  }
  .status-subtitle-lg {
    font-size: 24px;
  }

  // Tutorial
  .carousel-control-icon {
    font-size: 2rem;
  }

  .carousel-indicators li {
    border-top: $primary;
    background-color: $primary;
  }

  .carousel-indicators .active {
    border-top: darken($color: $primary, $amount: 5);
    // background-color: darken($color: $primary, $amount: 5);
  }

  .tutorial-emoji {
    font-size: 72px;
    margin-bottom: 16px;
  }

  .tutorial-lead {
    font-size: 1.25rem;
  }

  .tutorial-page {
    margin-left: 16px;
    margin-right: 16px;
    width: 500px;
  }

  .tutorial-title {
    font-size: 36px;
    margin-bottom: 18px;
  }
}

@include media-breakpoint-up(md) {
  .slug-width {
    min-width: 768px !important;
    max-width: 768px !important;
  }
}
